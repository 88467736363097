<template>
  <div id="st-container" class="st-container theme1">
    <v-select
      v-model="selected"
      :options="destinationOptions"
      dir="rtl"
      :searchable="stateSearchable"
      :clearable="false"
      :placeholder="$t('search-tab.choose-destination')"
      @option:selecting="selecting"
      @search:focus="deselecting"
      @close="closeDropDown"
      class="style-chooser"
      :disabled="disabled"
    >
      <template #selected-option="{ label }">
        <div style="display: flex; align-items: baseline; position: relative;">
          <span style="margin-left:10px;">
            <i :class=" category && category.packageType === 'F' ? 'fa fa-plane' : 'fa fa-hotel'" aria-hidden="true"></i>
          </span>
          {{ label }}
        </div>
      </template>
      <template v-slot:option="{ label, value }">
        <div style="display:flex; position: relative; align-items:flex-start">
          <span style="margin-left:10px;font-size:1.5rem;">
            <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
          </span>
          {{ label }}
          <div class="hotDestOption" v-if="isHotDestination(value)"><span >{{$t("home.hot")}}</span></div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import TemplateSearchSelect from './TemplateSearchSelect';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'SelectTheme1',
  mixins: [TemplateSearchSelect],
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
.hotDestPicked {
    left: 3px;
    position: absolute;
    background-color: #0061ab;
    color: white;
    border-radius: 20px;
    padding: 2px 6px;
    align-self: center;
    animation: blinkingText 2s infinite;
  }
  .hotDestOption {
    display:flex;
    left: 3px;
    margin-top: 3px;
    height: 18px;
    position: absolute;
    background-color: #0061ab;
    color: white;
    border-radius: 20px;
    padding: 0px 6px 5px 6px;
    align-self: center;
    align-items:center;
    animation: blinkingText 2s infinite;
  }
  .hotDestOption span {
    font-size: 12px;
    margin-top: 5px;
  }
  @keyframes blinkingText {
    0%   {background-color: #04a1d5;}
    25%  {background-color: #8e4860;}
    50%  {background-color: #ef0a1a;}
    75%  {background-color: #8e4860;}
    100% {background-color: #04a1d5;}
  }
</style>
<style>
input[type=search]{
  margin: 0;
  padding: 0;
  border: 0;
}
.theme1 .vs__dropdown-toggle {
  background: #fff !important;
  height: 48px;
}
.theme1 .vs--searchable .vs__dropdown-toggle {
  border-radius: 5px !important;
  padding: 0.575rem 1.35rem;
  height: auto;
}
.theme1 .vs__dropdown-toggle{
  border-radius: 5px !important;
}
.vs__selected{
  width: max-content;
}
.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
  z-index: 10;
}
.theme1 .style-chooser > ul > item{
  z-index: 1000;
}
.vs__dropdown-option {
    color: #333 !important;
}

</style>
